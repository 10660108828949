<h1 class="mt-0 type-h2">Are you still there?</h1>
<p>
  You've been away for a little while. You'll be logged out
  <br />
  due to inactivity shortly.
</p>
<mat-dialog-actions class="d-flex justify-content-end pt-0 mb-1">
  <msp-button
    [config]="{ type: 'flat', action: 'button', color: 'primary' }"
    (mspClick)="handleExtendSession()"
    [disabled]="disabled"
  >
    Stay logged in
  </msp-button>
</mat-dialog-actions>
