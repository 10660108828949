import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ResponsiveDirection, ResponsiveSpacing, WrapComponent } from '@mx51/chameleon-ui-angular';

interface Name {
  ariaLabel: string;
  styles?: string;
  value?: string;
}

interface Config {
  direction?: ResponsiveDirection;
  spacingX?: ResponsiveSpacing;
  spacingY?: ResponsiveSpacing;
  styles?: string;
  primary: Name;
  secondary: Name;
}

@Component({
  standalone: true,
  selector: 'sd-composite-name',
  templateUrl: './composite-name.component.html',
  styleUrls: ['./composite-name.component.scss'],
  imports: [CommonModule, WrapComponent],
})
export class CompositeNameComponent {
  @Input() set config(value: Config) {
    const { direction, spacingX, spacingY, primary, secondary } = value;
    const isPrimaryNameValid = primary.value?.trim().length > 0;

    this.direction = direction ?? 'horizontal';
    this.spacingX = spacingX ?? '$1';
    this.spacingY = spacingY ?? '0';
    this.styles = value.styles;

    if (isPrimaryNameValid) {
      this.primary = primary;
      this.secondary = secondary;
    } else {
      this.primary = {
        ...secondary,
        styles: primary.styles,
      };
      this.secondary = undefined;
    }
  }

  direction: ResponsiveDirection;
  spacingX: ResponsiveSpacing;
  spacingY: ResponsiveSpacing;
  styles?: string;
  primary: Name;
  secondary?: Name;
}
