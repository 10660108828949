export enum FeatureKey {
  DisputesViaFileIngestion = 'disputes.via_file_ingestion',
  MerchantFacilityAccess = 'merchant_facility_access',
  MerchantReports = 'merchant_reports',
  MdEnabled = 'md_enabled',
  SelfService = 'merchant_service_requests',
  SupportSlf = 'support.slf',
  SupportTlf = 'support.tlf',
  OnboardingConsumerErrorFile = 'onboarding_consumer.error_file_download',
  VasConnectService = 'vas_connect_service',
  ViewAuditLogEditUser = 'view_audit_log_edit_user',
}

export enum FeatureStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type FeatureList = {
  key: FeatureKey;
  status: FeatureStatus;
}[];

export interface GetFeaturesResp {
  data: FeatureList;
}
