import { ApiResponse } from '../interfaces';

export interface Transaction {
  id?: string;
  transaction_value?: number;
  settlement_value?: number;
  approval_status?: string;
  transaction_ts_ms?: number;
  transaction_type?: string;
  transaction_type_name?: string;
  processor_ref: string; // aka RRN
  card_transaction_detail?: {
    rrn: string;
    card_scheme_name: string;
    card_num_last_digits: string;
    expiry_month: number;
    expiry_year: number;
    holder_name?: string;
    card_scheme?: string;
    account_type: string;
    account_type_name: string;
    card_present: string;
    card_entry_mode: string;
    card_entry_mode_name: string;
    manual_card_number_source: string;
    manual_card_number_source_name: string;
    instore_card_app_name: string;
    refundable: false;
    auth_type: string;
  };
  mid?: string;
  tid?: string;
  gateway_response_code?: string;
  response_merchant_message?: string;
  cashout_amount?: number;
  tip_amount?: number;
  surcharge_amount?: number;
  authorisation_code?: string;
  pos_reference?: string;
  refundable?: boolean;
  instore?: boolean;
  is_expanded?: boolean;
  description?: string;
  create_ts_ms?: number;
  operator_id?: string;
  purchase_amount: number;
  currency?: string;
  organisation_id?: string;
  terminal_id?: string;
  merchant_facility_id?: string;
  expected_settlement_date?: string;
  gateway_resp_status?: string;
  gateway_response_message?: string;
}

export interface ListTransactionsResp {
  transactions: Transaction[];
  next_page_token?: string;
}

export interface TransactionAggregate {
  total_amount: number;
  purchase_amount: number;
  refund_amount: number;
  approved_count: number;
  declined_count: number;
}

export interface AggregateTransactionsResp extends ApiResponse<TransactionAggregate> {}

export interface MerchantFacilityFilter {
  merchant_facility_id: string;
  terminal_ids?: string[];
}

export interface ListTransactionFilterReq {
  organisation_id?: string;
  merchant_facilities?: MerchantFacilityFilter[];
  refundable_only?: boolean;
  rrn?: string;
  start_ts_ms: number;
  end_ts_ms: number;
  order_by?: string;
  page_size?: number;
  page_token?: string;
}

export interface AggregateTransactionsReq {
  organisation_id: string;
  merchant_facilities?: MerchantFacilityFilter[];
  start_ts_ms: number;
  end_ts_ms: number;
}

export enum TransactionDateFilter {
  Today = 'TODAY',
  Yesterday = 'YESTERDAY',
  ThisWeek = 'THISWEEK',
  Custom = 'CUSTOM',
}
