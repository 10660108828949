import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable, Subject, Subscription, timer } from 'rxjs';
import { distinctUntilChanged, mergeMap, retryWhen, take } from 'rxjs/operators';
import { webSocket, WebSocketSubject, WebSocketSubjectConfig } from 'rxjs/webSocket';

export const WS_RES = 'res';
export const WS_ANN = 'ann';
const RETRY_DELAY = 5000;

@Injectable()
export class WebsocketService {
  socket$: WebSocketSubject<any>;
  url = this.determineWebsocketUrl();
  connectionSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  wsSubjectConfig: WebSocketSubjectConfig<any> = {
    url: this.url,
    openObserver: { next: () => this.connectionSubject.next(true) },
    closeObserver: { next: () => this.connectionSubject.next(false) },
  };

  announcement = new Subject();
  wsSub: Subscription;

  public get connectionStatus$(): Observable<boolean> {
    return this.connectionSubject.pipe(distinctUntilChanged());
  }

  setupWebsocket() {
    this.socket$ = webSocket(this.wsSubjectConfig);
    this.subscribeToWebsocket();
  }

  tearDownWebsocket() {
    if (this.wsSub) {
      this.wsSub.unsubscribe();
    }
  }

  sendMessage(message: any): void {
    this.socket$.next(message);
  }

  private determineWebsocketUrl() {
    const domainPath = window.location.host + '/connect';
    if (window.location.protocol === 'https:') {
      return 'wss://' + domainPath;
    } else {
      return 'ws://' + domainPath;
    }
  }

  // Subscribe to the websocket to keep it alive until
  // tearDownWebsocket is called. The WS connection will get disconnected
  // it there is no subscription.
  private subscribeToWebsocket() {
    this.wsSub = this.socket$
      .pipe(
        retryWhen((errors) =>
          errors.pipe(
            mergeMap(() => {
              if (window.navigator.onLine) {
                console.warn(`Retrying in ${RETRY_DELAY}ms.`);
                return timer(RETRY_DELAY);
              } else {
                return fromEvent(window, 'online').pipe(take(1));
              }
            })
          )
        )
      )
      .subscribe((msg) => this.announcement.next(msg));
  }
}
