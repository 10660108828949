import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QueryService } from '@mx51/ngx-tanstack-query-adapter';
import { FeatureKey, FeatureList, FeatureStatus, GetFeaturesResp } from '@shared/interfaces/feature';
import { QueryKeys } from '@shared/services/query-keys.service';
import { environment } from 'environments/environment';
import { firstValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagLoaderService {
  private featureSetCore = new Set<FeatureKey>();

  get featureSet(): Set<FeatureKey> {
    return this.featureSetCore;
  }

  constructor(private http: HttpClient, private queryKeys: QueryKeys, private queryService: QueryService) {}

  fetchFeatures(): Observable<GetFeaturesResp> {
    const url = `${environment.api_base}feature-flags`;
    const headers = new HttpHeaders({ 'global-error-handler': 'false' });
    return this.http.get<GetFeaturesResp>(url, { headers });
  }

  async loadFeatures(): Promise<void> {
    try {
      const { data } = await this.queryService.queryClient.fetchQuery({
        queryKey: this.queryKeys.secure.featureFlags(),
        queryFn: () => firstValueFrom(this.fetchFeatures()),
        // Cache this request for 1hr
        staleTime: 1000 * 60 * 60,
      });
      this.featureSetCore = this.buildFeatureSet(data);
    } catch (error) {
      this.featureSetCore = new Set<FeatureKey>();
    }
  }

  private buildFeatureSet(featureList: FeatureList): Set<FeatureKey> {
    const featureSet = new Set<FeatureKey>();

    for (const activeFeature of featureList.filter((feature) => feature.status === FeatureStatus.Active)) {
      const indexOfFeatureKey = Object.values(FeatureKey).indexOf(activeFeature.key as FeatureKey);
      const featureKey = Object.keys(FeatureKey)[indexOfFeatureKey];

      if (featureKey) {
        featureSet.add(FeatureKey[featureKey]);
      }
    }

    return featureSet;
  }
}
