import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SsoIssuer } from '@models/user';
import { LoginApiService, LoginLibRoute, LoginLibService, SingleSignOnRoute } from '@mx51/login-lib';
import { HighlightEventService } from '@shared/services/highlight-event.service';
import { UserService } from '@shared/services/user.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(
    private router: Router,
    private loginService: LoginLibService,
    private matDialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private loginApiService: LoginApiService,
    private highlightEventService: HighlightEventService,
    private userService: UserService
  ) {}

  logout(): void {
    this.loginApiService.logout().subscribe({
      next: () => {
        this.highlightEventService.clearHighlightedEvent();
        this.redirectToLogin();
      },
      error: () => {
        this.highlightEventService.clearHighlightedEvent();
        this.redirectToLogin();
      },
    });
  }

  redirectToLogin(redirectString?: string): void {
    this.loginService.clearTimers();
    this.loginService.clearCookies();
    let query = {};
    if (redirectString && !redirectString.includes(LoginLibRoute.Login)) {
      query = { queryParams: { redirect: encodeURIComponent(redirectString) } };
    }

    const { sso_issuer } = this.userService.getUserDetail() ?? {};
    const route = sso_issuer === SsoIssuer.Mx51Okta ? SingleSignOnRoute.Mx51Okta : LoginLibRoute.Login;

    this.router.navigate([`/auth/${route}`], query);
  }

  clearModals(): void {
    this.matDialog.closeAll();
    this.matSnackBar.dismiss();
  }
}
