<div mxc-wrap [direction]="direction" [ngClass]="styles" [spacingX]="spacingX" [spacingY]="spacingY">
  <span class="mxc-sr-only">{{ primary.ariaLabel }} {{ primary.value }}</span>
  <span class="mxc-truncate ... mxc-max-w-full" aria-hidden="true" [ngClass]="primary.styles" [title]="primary.value">
    {{ primary.value }}
  </span>
  <ng-container *ngIf="secondary?.value">
    <span class="mxc-sr-only">{{ secondary.ariaLabel }} {{ secondary.value }}</span>
    <div class="mxc-truncate ... mxc-max-w-full mxc-flex" [ngClass]="secondary.styles" [title]="secondary.value">
      <span class="mxc-truncate ..." aria-hidden="true">
        {{ secondary.value }}
      </span>
    </div>
  </ng-container>
</div>
