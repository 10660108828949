import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class CustomTitleStrategy extends TitleStrategy {
  constructor(private readonly title: Title) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    const appTitle = 'Support Dashboard';

    if (title !== undefined) {
      this.title.setTitle(`${title} | ${appTitle}`);
    } else {
      this.title.setTitle(appTitle);

      if (!environment.production) {
        console.log(`No title set for ${routerState.url}, falling back to "${appTitle}"`);
      }
    }
  }
}
