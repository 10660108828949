import { Component, Input } from '@angular/core';

import { MetricModes } from '../hero-metric.component';

@Component({
  selector: 'sd-hero-metric-subtext',
  templateUrl: './hero-metric-subtext.component.html',
})
export class HeroMetricSubtextComponent {
  metricModes = MetricModes;

  @Input() highlightedNumber?: number;
  @Input() highlightedClass?: 'type-success' | 'type-error' | 'type-info';
  @Input() subsequentText: string;
  @Input() mode: MetricModes;
}
