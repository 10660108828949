import { Injectable } from '@angular/core';
import { EventMap } from '@models/event';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HighlightEventService {
  private readonly highlightedEventsKey = 'highlightedEvents';

  private readonly highlightedEvents: Map<string, EventMap>;
  private readonly highlightedEventsSubject = new ReplaySubject<Map<string, EventMap>>();
  private readonly highlightedEvents$: Observable<Map<string, EventMap>>;

  constructor() {
    this.highlightedEvents = this.loadHighlightedEvents();
    this.highlightedEvents$ = this.highlightedEventsSubject.asObservable();

    this.highlightedEventsSubject.next(this.highlightedEvents);
  }

  private saveHighlightedEvents(events: Map<string, EventMap>) {
    const obj = {};
    events.forEach((value, key) => {
      obj[key] = value;
    });

    localStorage.setItem(this.highlightedEventsKey, JSON.stringify(obj));
  }

  private loadHighlightedEvents(): Map<string, EventMap> {
    const json = localStorage.getItem(this.highlightedEventsKey);
    try {
      return new Map(Object.entries(JSON.parse(json)));
    } catch {
      return new Map();
    }
  }

  getHighlightedEvents(): Observable<Map<string, EventMap>> {
    return this.highlightedEvents$;
  }

  highlightEvent(event: EventMap): void {
    this.highlightedEvents.set(event.id, event);
    this.afterChange();
  }

  unHighlightEvent(eventID: string): void {
    this.highlightedEvents.delete(eventID);
    this.afterChange();
  }

  clearHighlightedEvent(): void {
    this.highlightedEvents.clear();
    this.afterChange();
  }

  private afterChange(): void {
    this.highlightedEventsSubject.next(this.highlightedEvents);
    this.saveHighlightedEvents(this.highlightedEvents);
  }
}
