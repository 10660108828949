import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LoginApiService } from '@mx51/login-lib';

@Component({
  selector: 'sd-inactivity-dialog',
  templateUrl: './inactivity-dialog.component.html',
  styleUrls: ['./inactivity-dialog.component.scss'],
})
export class InactivityDialogComponent {
  disabled: boolean;

  constructor(public dialogRef: MatDialogRef<InactivityDialogComponent>, private loginApiService: LoginApiService) {}

  handleExtendSession() {
    this.disabled = true;
    this.loginApiService.extendSession().subscribe(
      () => {
        this.dialogRef.close(true);
      },
      () => (this.disabled = false)
    );
  }
}
