<ng-container *ngIf="isVisible">
  <div
    class="mxc-fixed mxc-right-8 mxc-left-4 mxc-bottom-4 xs:mxc-left-auto mxc-bg-semantic-bg-neutral-page mxc-shadow-elevation-lg mxc-p-4 mxc-rounded-md mxc-border-2 mxc-border-semantic-border-warning-default mxc-overflow-x-hidden mxc-z-docked"
    *ngIf="isMenuOpen"
  >
    <div class="mxc-sticky">
      <h2 class="mxc-mixin-header-sm-semibold mxc-mt-0">Release flags</h2>
      <ng-container *ngIf="hasReleaseFlags; else noReleaseFlags">
        <div class="mxc-mb-3 mxc-flex mxc-flex-col">
          <mxc-checkbox
            *ngFor="let key of releaseFlagKeys"
            [isChecked]="isFlagEnabled(key)"
            (onChange)="toggleReleaseFlag(key)"
            color="primary"
            class="mxc-mb-4"
          >
            {{ key }}
          </mxc-checkbox>
        </div>
      </ng-container>
      <div class="mxc-flex mxc-justify-end">
        <button mxc-button variant="outline" (click)="isMenuOpen = false">Close</button>
      </div>
    </div>
  </div>

  <div
    class="mxc-fixed mxc-right-0 mxc-bottom-0 mxc-shadow-elevation-lg mxc-mb-4 mxc-mr-8 mxc-z-docked"
    *ngIf="!isMenuOpen"
  >
    <button mxc-icon-button aria-label="Show release flags" (click)="isMenuOpen = true">
      <mxc-icon name="OutlineFlag"></mxc-icon>
    </button>
  </div>

  <ng-template #noReleaseFlags>
    <p>There are no release flags available</p>
  </ng-template>
</ng-container>
