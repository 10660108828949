import { HttpParameterCodec, HttpUrlEncodingCodec } from '@angular/common/http';

/**
 * A custom url encoder for HttpParams which allows us to urlencode characters
 * such as +
 * The default (HttpUrlEncodingCodec) does encodeURIComponent but then replaces
 * the following characters: ! $ \' ( ) * + , ; A 9 - . _ ~ ? /
 * with the unencoded (raw) versions (according to https://tools.ietf.org/html/rfc3986)
 *
 */

export class ParamURLEncodingCodec extends HttpUrlEncodingCodec implements HttpParameterCodec {
  encodeValue(value: string): string {
    return super.encodeValue(value).replace(/\+/g, '%2B');
  }
}
