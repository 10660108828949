import { ApiResponse } from '@app/interfaces';

import { MembershipStatus, MembershipStatusDetails } from './membership';

export enum UserType {
  Acquirer = 'ACQUIRER',
  Merchant = 'MERCHANT',
  Operator = 'OPERATOR',
  ThirdParty = 'THIRD_PARTY',
  Unspecified = 'UNSPECIFIED',
}

export enum ProvisionUserTypeEnum {
  Acquirer = 3,
  Merchant = 1,
  Operator = 2,
  Unspecified = 0,
}

export enum ProvisionUserType {
  Acquirer = UserType.Acquirer,
  Merchant = UserType.Merchant,
  Operator = UserType.Operator,
  Unspecified = UserType.Unspecified,
}

export interface Address {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  postcode: string;
  country: string;
  isPrimary: boolean;
}

export interface User {
  id: string;
  email: string;
  username: string;
  first_name: string;
  last_name: string;
  mobile_number: string;
  locked_out?: boolean;
  login_attempt_count: number;
  gdpr_consent_ts_ms: number;
  successful_login_count: number;
  user_type: UserType;
  status: number;
  org_memberships?: MembershipStatusDetails[];
  last_successful_login_ts_ms: number;
  last_login_attempt_ts_ms: number;
}

// The user search result has significant overlap with UserRecord, but not 100%,
// so we've taken this approach.
// Unfortunately both types also differ from existing "User" type (above)
// Hopefully in the future the BE for orgs/members that is used by "User" will be
// refactored to match these newer data shapes
export type UserSearchResult = Omit<UserRecord, 'mobile_number' | 'locked_out' | 'owned_by_tenant'>;
export type UserProfileData = Pick<
  UserRecord,
  'first_name' | 'last_name' | 'acquirer_reference' | 'email_address' | 'mobile_number'
>;
export interface UserRecord {
  id: string;
  username: string;
  email_address: string;
  acquirer_reference: string;
  first_name: string;
  last_name: string;
  mobile_number: string;
  locked_out: boolean;
  user_type: UserType;
  status: MembershipStatus;
  create_ts_ms: number;
  close_ts_ms?: number;
  last_successful_login_ts_ms: number;
  last_login_attempt_ts_ms: number;
  successful_login_count: number;
  login_attempt_count: number;
  owned_by_tenant: boolean;
  sso_issuer?: SsoIssuer;
}

export interface NewUser {
  email: string;
  first_name: string;
  last_name: string;
  mobile_number: string;
  acquirer_reference?: string;
}

export interface ProvisionUserReq extends NewUser {
  organisation_id: string;
  group_ids: string[];
}

export interface NewlyCreatedUser extends NewUser {
  id: string;
  username: string;
}

export interface ProvisionUserResp extends ApiResponse<NewlyCreatedUser> {}

export enum UserStatus {
  Active = 1,
  Closed,
}

// This could be deprecated/removed, but users.component will need to be refactored
// The only reason it's not a copy of UserRecordStatus is because we can't alias
// enums and retain the object functionality (use as value as well as type e.g. value = enum.KEY)
export enum UserSearchResultStatus {
  Active = 'ACTIVE',
  Closed = 'CLOSED',
}

// This could be deprecated in favour of ApiResponse generic, however some refactoring of users.component
// will be required
export interface UsersSearchResultResp {
  meta?: { next_page_token?: string };
  data: UserSearchResult[];
}

export interface UsersSearch {
  pageSize?: number;
  pageToken?: string;
  searchString: string;
  userTypes?: UserType[];
}

export interface AddUserOrgMembershipReq {
  organisation_id: string;
  group_ids: string[];
  owned_by_tenant?: boolean;
}

export interface AddUserOrgMembershipResp {
  data: {
    id: string;
    user_id: string;
    organisation_id: string;
    suspended: boolean;
    update_org_membership_initiated_by: number;
    update_org_membership_initiated_by_text: UserType;
    status: number;
    status_text: MembershipStatus;
    close_ts_ms: number;
    org_close_ts_ms: number;
    provisioned_by_type: number;
    provisioned_by_type_text: ProvisionUserType;
    provisioned_ts_ms: number;
  };
}

export enum InvitationStatus {
  Pending = 'PENDING',
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Revoked = 'REVOKED',
}

export enum InvitationAction {
  Resend = 'Resend',
  Revoke = 'Revoke',
}

export const InvitationStatusLabelMap = {
  [InvitationStatus.Pending]: 'Pending',
  [InvitationStatus.Accepted]: 'Accepted',
  [InvitationStatus.Declined]: 'Declined',
  [InvitationStatus.Revoked]: 'Revoked',
};

export enum SsoIssuer {
  Amazon = 'amazon',
  Mx51Okta = 'mx51okta',
}

export interface Invitation {
  id: string;
  organisation_id: string;
  organisation_name: string;
  inviter: {
    id: string;
    first_name: string;
    last_name: string;
  };
  status: InvitationStatus;
  groups: {
    id: string;
    name: string;
  }[];
  email: string;
  create_ts_ms?: number;
  update_ts_ms?: number;
  last_sent_ts_ms?: number;
  accept_ts_ms?: number;
  decline_ts_ms?: number;
  revoke_ts_ms?: number;
  allowed_merchant_facility_ids?: string[];
  unrestricted_merchant_facility_access: boolean;
}

export interface ListInvitationResp extends ApiResponse<Invitation[]> {}

export interface UpdateStatusReq {
  close_ts_ms?: number;
  status: MembershipStatus;
}
