import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SecureTenantConfig, SecureTenantConfigResponse } from '@shared/interfaces/tenant';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SecureTenantConfigService {
  private hasLoaded = false;
  private secureTenantConfigCore: SecureTenantConfig = {};

  get merchantFacilityAllowedCountries(): string[] {
    return this.secureTenantConfigCore.merchant_facility_allowed_countries ?? [];
  }

  get mobileNumberAllowedCountries(): string[] {
    return this.secureTenantConfigCore.mobile_number_allowed_countries ?? [];
  }

  constructor(private http: HttpClient) {}

  loadSecureTenantConfig(): Observable<boolean> {
    if (this.hasLoaded) {
      return of(true);
    }

    const url = `${environment.api_base}tenant-config`;
    const headers = new HttpHeaders({ 'global-error-handler': 'false' });

    return this.http.get<SecureTenantConfigResponse>(url, { headers }).pipe(
      tap(({ data }) => {
        this.secureTenantConfigCore = data;
        this.hasLoaded = true;
      }),
      map(() => true),
      catchError(() => {
        return of(false);
      })
    );
  }
}
