import { Injectable } from '@angular/core';
import { LoginLibService } from '@mx51/login-lib';

/**
 * Query key guidelines:
 * 1. Keys used pre-authentication belong in the public keys prop.
 * 2. Keys used post-authentication belong in the secure keys prop. These keys will be prefixed with the current
 *    session id automatically. This ensures sensitive data cannot leak between user sessions.
 * 3. Branch keys by feature or type of thing at the most granular level that makes sense. Specify an all() key. The
 *    all() key should only serve as the base for other keys and not be called directly except when invalidating
 *    queries. Invalidating via the all() key is especially useful for invalidating all cached pages of a paginated
 *    list.
 *
 *    Do - branch by feature:
 *    ```
 *    {
 *      disputes: {
 *        all: () => ['disputes']
 *      }
 *      transactions: {
 *        all: () => ['transactions']
 *      }
 *     }
 *     ```
 *
 *    Avoid - mixing features:
 *    ```
 *    {
 *      disputes: () => ['disputes']
 *      transactions: () => ['transactions']
 *    }
 *    ```
 *
 *    Do - use the all() key as a base for other keys:
 *    ```
 *    {
 *      disputes: {
 *        all: () => ['disputes'],
 *        list: () => [...disputes.all(), 'list']
 *      }
 *    }
 *    ```
 *
 *    Do - query keys for specific actions:
 *    ```
 *    queryService.useQuery(queryKeys.disputes.list(), () => {})
 *    ```
 *
 *    Avoid - querying the all() key directly:
 *    ```
 *    queryService.useQuery(queryKeys.disputes.all(), () => {})
 *    ```
 *
 *    Do - leverage the all() key to invalidate ALL queries for a feature:
 *    ```
 *    queryService.invalidateQueries(queryKeys.disputes.all())
 *    ```
 *
 *  4. Branch individual entities separately. Require the entity id as a parameter at the root of the branch. For
 *     individual entities specify a byId() key instead of an all(). The byId() key should be used to get an entity by
 *     id.
 *
 *     Do - branch individual entities separately:
 *     ```
 *     {
 *      dispute: (disputeId: string) => {
 *        byId: () => ['dispute', disputeId]
 *      },
 *      disputes: {
 *        all: () => ['disputes'],
 *        list: () => [...disputes.all(), 'list']
 *      }
 *     }
 *     ```
 *
 *     Avoid - mixing individual entities with features:
 *     ```
 *     {
 *      disputes: {
 *        all: () => ['disputes'],
 *        byId: (disputeId: string) => [...disputes.all(), disputeId],
 *        list: () => [...disputes.all(), 'list']
 *      }
 *     }
 *     ```
 *
 *    Do - query the byId() key directly:
 *    ```
 *    queryService.useQuery(queryKeys.dispute(disputeId).byId(), () => {})
 *    ```
 *
 *   Do - leverage the byId() key to invalidate ALL queries for an entity:
 *   ```
 *   queryService.useQuery(queryKeys.dispute(disputeId).byId(), () => {})
 *   ```
 *
 * 5. The byId() key should also serve as the base for any other keys directly related to the entity.
 *
 *   Do - branch related entities off of the byId() key:
 *   ```
 *   {
 *    dispute: (disputeId: string) => {
 *      byId: () => ['dispute', disputeId]
 *      comment: () => [...dispute(disputeId).byId(), 'comment']
 *    }
 *   }
 *   ```
 *
 * 6. Nest to any level to keep things organised.
 *
 *  Avoid - lumping all keys in a single bucket:
 *   ```
 *  {
 *    dispute: (disputeId: string) => {
 *      byId: () => ['dispute', disputeId]
 *      listActivities: () => [...dispute(disputeId).byId(), 'list-activities']
 *      searchActivities: () => [...dispute(disputeId).byId(), 'search-activities']
 *    }
 *  }
 *  ```
 *
 *  Do - nest keys when required:
 *  ```
 *  {
 *    dispute: (disputeId: string) => {
 *      byId: () => ['dispute', disputeId]
 *      activities: {
 *        all: () => [...dispute(disputeId).byId(), 'activities'],
 *        list: () => [...dispute(disputeId).activities.all(), 'list']
 *        search: () => [...dispute(disputeId).activities.all(), 'search']
 *      }
 *    }
 *  }
 *  ```
 *
 * 7. Only add keys for mutations if you have a specific reason to do so. The two use cases for mutation keys are
 *    inheriting defaults and debugging.
 */
@Injectable({
  providedIn: 'root',
})
export class QueryKeys {
  public = {}; // This is where I would put my public query keys, if I had any!!
  secure = {
    all: ['session', this.sessionId],
    alert: (ticketNumber: number) => {
      return {
        byTicketNumber: () => [...this.secure.all, 'alert', ticketNumber],
      };
    },
    alerts: {
      all: () => [...this.secure.all, 'alerts'],
      assignees: {
        all: () => [...this.secure.alerts.all(), 'assignees'],
        list: () => [...this.secure.alerts.assignees.all(), 'list'],
      },
      countOutstanding: () => [...this.secure.alerts.all(), 'count-outstanding'],
      types: {
        all: () => [...this.secure.alerts.all(), 'types'],
        list: () => [...this.secure.alerts.types.all(), 'list'],
      },
    },
    featureFlags: () => [...this.secure.all, 'feature-flags'],
    org: (orgId: string) => {
      return {
        byId: () => [...this.secure.all, 'organisation', orgId],
        invitations: {
          all: () => [...this.secure.org(orgId).byId(), 'invitations'],
          list: (options: object) => [...this.secure.org(orgId).invitations.all(), 'list', options],
        },
        members: {
          all: () => [...this.secure.org(orgId).byId(), 'members'],
          audit: (options: object) => [...this.secure.org(orgId).members.all(), 'audit', options],
          list: (options: object) => [...this.secure.org(orgId).members.all(), 'list', options],
        },
        merchantFacility: (merchantFacilityId: string) => {
          return {
            byId: () => [...this.secure.org(orgId).byId(), 'merchant-facility', merchantFacilityId],
            dispute: (disputeId: string) => {
              return {
                byId: () => [
                  ...this.secure.org(orgId).merchantFacility(merchantFacilityId).byId(),
                  'dispute',
                  disputeId,
                ],
                activities: {
                  all: () => [
                    ...this.secure.org(orgId).merchantFacility(merchantFacilityId).dispute(disputeId).byId(),
                    'activities',
                  ],
                  list: () => [
                    ...this.secure.org(orgId).merchantFacility(merchantFacilityId).dispute(disputeId).activities.all(),
                    'list',
                  ],
                },
                evidences: {
                  all: () => [
                    ...this.secure.org(orgId).merchantFacility(merchantFacilityId).dispute(disputeId).byId(),
                    'evidences',
                  ],
                  list: () => [
                    ...this.secure.org(orgId).merchantFacility(merchantFacilityId).dispute(disputeId).evidences.all(),
                    'list',
                  ],
                },
              };
            },
            logo: (fileId: string) => {
              return {
                byFileId: () => [...this.secure.org(orgId).merchantFacility(merchantFacilityId).byId(), 'logo', fileId],
              };
            },
            terminal: (terminalId: string) => {
              return {
                byId: () => [
                  ...this.secure.org(orgId).merchantFacility(merchantFacilityId).byId(),
                  'terminal',
                  terminalId,
                ],
                audit: () => [
                  ...this.secure.org(orgId).merchantFacility(merchantFacilityId).terminal(terminalId).byId(),
                  'audit',
                ],
              };
            },
          };
        },
        merchantFacilities: {
          all: () => [...this.secure.org(orgId).byId(), 'merchant-facilities'],
          list: (options: object) => [...this.secure.org(orgId).merchantFacilities.all(), 'list', options],
        },
        vasEnrolments: {
          all: () => [...this.secure.org(orgId).byId(), 'vas-enrolments'],
          list: () => [...this.secure.org(orgId).vasEnrolments.all(), 'list'],
        },
      };
    },
    user: (userId: string) => {
      return {
        byId: () => [...this.secure.all, 'user', userId],
        invitations: {
          all: () => [...this.secure.user(userId).byId(), 'invitations'],
          list: (options: object) => [...this.secure.user(userId).invitations.all(), 'list', options],
        },
        orgMemberships: {
          all: () => [...this.secure.user(userId).byId(), 'org-memberships'],
          list: (options: object) => [...this.secure.user(userId).orgMemberships.all(), 'list', options],
        },
      };
    },
    users: {
      all: () => [...this.secure.all, 'users'],
      search: (options: object) => [...this.secure.users.all(), 'search', options],
    },
  };

  private get sessionId(): string {
    return this.loginLibService.getSessionId();
  }

  constructor(private loginLibService: LoginLibService) {}
}
