<div class="page-not-found-container d-flex align-items-center type-muted px-md-40">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6 p-24">
        <h1 class="type-size-md-36 type-size-lg-42 mt-0 mt-md-20 type-weight-500">Page not found</h1>
        <p class="type-size-md-20 type-size-lg-24 type-line-height-lg-35">
          Sorry the page you are looking for could not be found.
        </p>
      </div>
      <div class="col-12 col-md-6 p-24">
        <img src="/assets/background/people.svg" alt="people" class="" />
      </div>
    </div>
  </div>
</div>
