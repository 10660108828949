import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private snackBarService: MatSnackBar) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const useGlobalHandler = req.headers.get('global-error-handler') !== 'false';
    if (!useGlobalHandler) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        // 401 error is handled by login-lib's session-interceptor
        if (error && error.status !== 401) {
          this.getErrorMessage(error).then((errorMsg) => {
            this.notifyUser(errorMsg);
          });
        }
        return throwError(error);
      })
    );
  }

  async getErrorMessage(error: HttpErrorResponse): Promise<string> {
    if (error.error instanceof ErrorEvent) {
      return error.error.message;
    }
    if (error.error instanceof Blob) {
      const text = await error.error.text();
      try {
        const jsonError = JSON.parse(text);
        return jsonError.error || error.statusText;
      } catch {
        return error.statusText;
      }
    }
    // BFF returns the detailed error message in error.error.error
    return error.error.error || error.statusText;
  }

  notifyUser(errorMsg: string) {
    this.snackBarService.open(`Error: ${errorMsg}`, 'Close', {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }
}
