import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { DateAdapter, MAT_DATE_FORMATS, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatLuxonDateModule } from '@angular/material-luxon-adapter';
import { RouterModule } from '@angular/router';
import { CompositeNameComponent } from '@app/modules/shared/components/composite-name/composite-name.component';
import { TimeZonePipe } from '@app/pipes/timezone.pipe';
import { IconComponent } from '@mx51/chameleon-icons-angular';
import {
  AlertComponent,
  AlertIconComponent,
  AlertTitleComponent,
  ButtonComponent,
  CardBodyComponent,
  CardComponent,
  CardHeaderComponent,
  CheckboxComponent,
  CloseButtonComponent,
  ComboboxComponent,
  ComboboxOptionComponent,
  ControlDirective,
  DividerComponent,
  ErrorTextComponent,
  FormControlComponent,
  FormControlErrorDirective,
  HelpTextComponent,
  HStackComponent,
  IconButtonComponent,
  InputComponent,
  InputElementComponent,
  InputStatusIconComponent,
  InputWrapComponent,
  LabelComponent,
  RadioComponent,
  SelectCaretComponent,
  SelectComponent,
  SelectInputComponent,
  SelectWrapComponent,
  SpinnerComponent,
  VStackComponent,
} from '@mx51/chameleon-ui-angular';
import {
  AlertModule,
  ButtonModule,
  DataViewsModule,
  IconModule,
  MobileNumberInputComponent,
  PhoneNumberInputComponent,
  ProgressLineModule,
} from '@mx51/shared-components';
import { NumberCounterModule } from '@mx51/shared-components';
import { TransactionsSelectedDateTimeRangeComponent } from '@shared/components/transactions-selected-date-time-range/transactions-selected-date-time-range.component';
import { environment } from 'environments/environment';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';

import { AccessTypeSelectComponent } from './components/access-type-select/access-type-select.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { DatePickerDialogComponent } from './components/date-picker-dialog/date-picker-dialog.component';
import { DateTimePickerDialogComponent } from './components/date-time-picker-dialog/date-time-picker-dialog.component';
import { DateTimeRangeDialogComponent } from './components/date-time-range-dialog/date-time-range-dialog.component';
import { GenericStatusBadgeComponent } from './components/generic-status-badge/generic-status-badge.component';
import { HeroMetricSubtextComponent } from './components/hero-metric/hero-metric-subtext/hero-metric-subtext.component';
import { HeroMetricComponent } from './components/hero-metric/hero-metric.component';
import { InactivityDialogComponent } from './components/inactivity-dialog/inactivity-dialog.component';
import { InvitationStatusBadgeComponent } from './components/invitation-status-badge/invitation-status-badge.component';
import { ListCardSchemesComponent } from './components/list-card-schemes/list-card-schemes.component';
import { MembershipStatusBadgeComponent } from './components/membership-status-badge/membership-status-badge.component';
import { MobileNumberFieldComponent } from './components/mobile-number-field/mobile-number-field.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PermissionOutlineComponent } from './components/permission-outline/permission-outline.component';
import { ResetPasswordDialogComponent } from './components/reset-password-dialog/reset-password-dialog.component';
import { UnlockUserDialogComponent } from './components/unlock-user-dialog/unlock-user-dialog.component';
import { UpdateUserAccountStatusDialogComponent } from './components/update-user-account-status-dialog/update-user-account-status-dialog.component';
import { UpdateUserProfileDialogComponent } from './components/update-user-profile-dialog/update-user-profile-dialog.component';
import { UserListComponent } from './components/user-list/user-list.component';
import { DisableControlDirective } from './directives/disable-control.directive';
import { IfPermissionDirective } from './directives/if-permission.directive';
import { OnceOffEditFormDirective } from './directives/once-off-edit-form.directive';
import { RouterLinkActiveDirective } from './directives/router-link-active.directive';
import { CentsPipe } from './pipes/cents.pipe';
import { FileSizePipe } from './pipes/file-size.pipe';
import { FriendlyNamePipe } from './pipes/friendly-name.pipe';
import { MerchantFacilityAccessPipe } from './pipes/merchant-facility-access.pipe';
import { PluckedAndJoinedStringPipe } from './pipes/plucked-and-joined-string.pipe';
import { TimeOfDayPipe } from './pipes/time-of-day.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { UpperCaseFirstPipe } from './pipes/upper-case-first.pipe';
import { UserInitialsPipe } from './pipes/user-initials.pipe';
import { APP_DATE_FORMATS, AppDateAdapter } from './services/app-date-adapter.service';
import { AuthService } from './services/auth.service';
import { SLFConsumerService } from './services/slf-consumer.service';
import { TLFConsumerService } from './services/tlf-consumer.service';
import { UtilService } from './services/util.service';
import { WebsocketService } from './services/websocket.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatOptionModule,
    MatSelectModule,
    MatTooltipModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatSnackBarModule,
    ButtonModule,
    IconModule,
    NumberCounterModule,
    CollapseModule,
    AlertModule,
    ProgressLineModule,
    MatDatepickerModule,
    MatLuxonDateModule,
    RouterModule,
    MobileNumberInputComponent,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    DataViewsModule,
    UserInitialsPipe,
    NgxMatTimepickerModule.setLocale(environment.DEFAULT_LOCALE),
    TimeZonePipe,
    CompositeNameComponent,
    VStackComponent,
    HStackComponent,
    CheckboxComponent,
    CardComponent,
    CardBodyComponent,
    CardHeaderComponent,
    DividerComponent,
    FormControlComponent,
    ControlDirective,
    SelectComponent,
    SelectInputComponent,
    SelectWrapComponent,
    LabelComponent,
    IconComponent,
    InputWrapComponent,
    InputComponent,
    InputElementComponent,
    ErrorTextComponent,
    FormControlErrorDirective,
    InputStatusIconComponent,
    HelpTextComponent,
    SpinnerComponent,
    SelectCaretComponent,
    RadioComponent,
    PhoneNumberInputComponent,
    ButtonComponent,
    ComboboxComponent,
    ComboboxOptionComponent,
    AlertComponent,
    IconButtonComponent,
    AlertIconComponent,
    AlertTitleComponent,
    CloseButtonComponent,
  ],
  declarations: [
    PageNotFoundComponent,
    InactivityDialogComponent,
    CentsPipe,
    TruncatePipe,
    PluckedAndJoinedStringPipe,
    UpperCaseFirstPipe,
    TimeOfDayPipe,
    UserListComponent,
    UpdateUserProfileDialogComponent,
    ResetPasswordDialogComponent,
    IfPermissionDirective,
    DisableControlDirective,
    PermissionOutlineComponent,
    RouterLinkActiveDirective,
    UpdateUserAccountStatusDialogComponent,
    UnlockUserDialogComponent,
    DatePickerDialogComponent,
    OnceOffEditFormDirective,
    AutocompleteComponent,
    MembershipStatusBadgeComponent,
    InvitationStatusBadgeComponent,
    MobileNumberFieldComponent,
    AccessTypeSelectComponent,
    UpperCaseFirstPipe,
    FileSizePipe,
    GenericStatusBadgeComponent,
    ListCardSchemesComponent,
    DateTimePickerDialogComponent,
    DateTimeRangeDialogComponent,
    TransactionsSelectedDateTimeRangeComponent,
    HeroMetricComponent,
    HeroMetricSubtextComponent,
    FriendlyNamePipe,
    MerchantFacilityAccessPipe,
  ],
  exports: [
    PageNotFoundComponent,
    InactivityDialogComponent,
    CentsPipe,
    TruncatePipe,
    PluckedAndJoinedStringPipe,
    UpperCaseFirstPipe,
    TimeOfDayPipe,
    UserListComponent,
    UpdateUserProfileDialogComponent,
    ResetPasswordDialogComponent,
    IfPermissionDirective,
    DisableControlDirective,
    RouterLinkActiveDirective,
    UpdateUserAccountStatusDialogComponent,
    UnlockUserDialogComponent,
    DatePickerDialogComponent,
    OnceOffEditFormDirective,
    AutocompleteComponent,
    MembershipStatusBadgeComponent,
    InvitationStatusBadgeComponent,
    MobileNumberFieldComponent,
    AccessTypeSelectComponent,
    UpperCaseFirstPipe,
    FileSizePipe,
    GenericStatusBadgeComponent,
    ListCardSchemesComponent,
    DateTimePickerDialogComponent,
    DateTimeRangeDialogComponent,
    TransactionsSelectedDateTimeRangeComponent,
    HeroMetricComponent,
    HeroMetricSubtextComponent,
    FriendlyNamePipe,
    MerchantFacilityAccessPipe,
    CheckboxComponent,
    FormControlComponent,
    ControlDirective,
    SelectComponent,
    SelectInputComponent,
    SelectWrapComponent,
    LabelComponent,
    IconComponent,
    InputWrapComponent,
    InputComponent,
    InputElementComponent,
    ErrorTextComponent,
    FormControlErrorDirective,
    InputStatusIconComponent,
    CardComponent,
    CardBodyComponent,
    CardHeaderComponent,
    HelpTextComponent,
    RadioComponent,
    PhoneNumberInputComponent,
    VStackComponent,
    HStackComponent,
    DividerComponent,
    ButtonComponent,
  ],
  providers: [
    AuthService,
    WebsocketService,
    TLFConsumerService,
    SLFConsumerService,
    UtilService,
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
})
export class SharedModule {}
