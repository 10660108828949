import { Inject, Injectable, Optional } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { DateTime } from 'luxon';

export const APP_DATE_FORMATS = {
  parse: {
    dateInput: ['dd MMM yyyy'],
  },
  display: {
    dateInput: 'input',
    monthYearLabel: 'MM yyyy',
    dateA11yLabel: 'dd MMM yyyy',
    monthYearA11yLabel: 'MMM yyyy',
  },
};

@Injectable()
export class AppDateAdapter extends LuxonDateAdapter {
  constructor(@Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string) {
    super(dateLocale);
  }

  format(date: DateTime, displayFormat: string): string {
    if (displayFormat === 'input') {
      const today = DateTime.now().startOf('day');
      const startOfDate = date.startOf('day');
      if (startOfDate.toMillis() === today.toMillis()) {
        return 'Today';
      }

      return date.toFormat('d MMM yyyy');
    }

    return date.toFormat('EEE MMM d yyyy');
  }
}
