import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { LoginLibService } from '@mx51/login-lib';
import { SecureTenantConfigService } from '@shared/services/secure-tenant-config.service';
import { UserService } from '@shared/services/user.service';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { FeatureFlagLoaderService } from './modules/shared/services/feature-flag-loader.service';

@Injectable({
  providedIn: 'root',
})
export class SecureGuard {
  constructor(
    private secureTenantConfigService: SecureTenantConfigService,
    private featureFlagLoaderService: FeatureFlagLoaderService,
    private userService: UserService,
    private router: Router,
    private loginLibService: LoginLibService
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.activateOrRedirect();
  }

  canActivateChild(): Observable<boolean | UrlTree> {
    return this.activateOrRedirect();
  }

  private activateOrRedirect() {
    return forkJoin([
      this.userService.getMemberships(),
      this.secureTenantConfigService.loadSecureTenantConfig(),
      this.featureFlagLoaderService.loadFeatures(),
    ]).pipe(
      map(() => true),
      catchError((err) => {
        if (err.status === 401) {
          this.loginLibService.clearCookies();
          return of(this.router.parseUrl('/auth/login'));
        }
        return of(false);
      })
    );
  }
}
