import { Injectable } from '@angular/core';
import { TransactionDateFilter } from '@models/transaction';
import { DateTime, Duration } from 'luxon';

@Injectable()
export class UtilService {
  getHourList(): string[] {
    return [
      '00:00',
      '01:00',
      '02:00',
      '03:00',
      '04:00',
      '05:00',
      '06:00',
      '07:00',
      '08:00',
      '09:00',
      '10:00',
      '11:00',
      '12:00',
      '13:00',
      '14:00',
      '15:00',
      '16:00',
      '17:00',
      '18:00',
      '19:00',
      '20:00',
      '21:00',
      '22:00',
      '23:00',
    ];
  }

  /**
   * Get a combined timestamp from a Date and a time from getHourList(), returns now() if date is null.
   * @param date
   * @param time
   */
  getCombinedTs(date: DateTime | null, time?: string) {
    let combinedDate = date ? date.startOf('day') : DateTime.now();

    if (time) {
      const duration = Duration.fromISOTime(time);
      combinedDate = combinedDate.plus(duration);
    }
    return combinedDate.valueOf();
  }

  getStartEndTs(value: TransactionDateFilter): { startTs: number; endTs: number } {
    let fromDate: DateTime, toDate: DateTime;

    if (value === TransactionDateFilter.Yesterday) {
      fromDate = DateTime.now().plus({ days: -1 }).startOf('day');
      toDate = fromDate.endOf('day');
    } else if (value === TransactionDateFilter.ThisWeek) {
      fromDate = DateTime.now().startOf('week');
      toDate = fromDate.endOf('week');
    } else {
      // TODAY
      fromDate = DateTime.now().startOf('day');
      toDate = fromDate.endOf('day');
    }

    return {
      startTs: fromDate.valueOf(),
      endTs: toDate.valueOf(),
    };
  }
}
