import { CurrencyPipe } from '@angular/common';
import { LowerCasePipe } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NumberTypes, ShortNumberPipe } from '@mx51/shared-components';
import { Icon } from '@mx51/shared-components/lib/icon/icon.interface';

import { IntervalTypes } from '../../constants/insights';

export enum MetricModes {
  Comparison, //Text format in this mode will be '4.8% vs $890.31K previous month' for example
  Info, //Text format in this mode will be '78 out of 234 Chargebacks won' for example
}

@Component({
  selector: 'sd-hero-metric',
  templateUrl: './hero-metric.component.html',
  styleUrls: ['./hero-metric.component.scss'],
})
export class HeroMetricComponent implements OnChanges {
  metricModes = MetricModes;
  numberTypes = NumberTypes;

  @Input() heading: string;
  @Input() data: {
    currentCount: number;
    previousCount?: number;
    intervalPercentDiff?: number;
    intervalType?: IntervalTypes;
  };
  @Input() tooltipMessage: string;
  @Input() headingAriaLevel = 2;
  @Input() numberType: NumberTypes;
  @Input() isLoading: boolean;

  @Input() showReversedTrend = false;
  @Input() useShortNumber = true;
  @Input() metricMode = MetricModes.Comparison;
  @Input() freeTextForInfoMode?: { highlightedNumber: number; subsequentText: string };

  subsequentSubtext: string;
  highlightedNumber: number;
  highlightedClass: 'type-error' | 'type-success' | 'type-info';
  indicatorConfig: { [key: string]: Icon } = {
    upSuccessLarge: { name: 'icon-arrow-up', size: 'large', fill: 'success' },
    upErrorLarge: { name: 'icon-arrow-up', size: 'large', fill: 'error' },
    downSuccessLarge: { name: 'icon-arrow-down', size: 'large', fill: 'success' },
    downErrorLarge: { name: 'icon-arrow-down', size: 'large', fill: 'error' },
    upSuccessSmall: { name: 'icon-arrow-up', size: 'small', fill: 'success' },
    upErrorSmall: { name: 'icon-arrow-up', size: 'small', fill: 'error' },
    downSuccessSmall: { name: 'icon-arrow-down', size: 'small', fill: 'success' },
    downErrorSmall: { name: 'icon-arrow-down', size: 'small', fill: 'error' },
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.generateHighlightedText();
    }

    this.subsequentSubtext = this.getSubsequentText();
  }

  generateHighlightedText(): void {
    switch (this.metricMode) {
      case MetricModes.Info: {
        this.highlightedNumber = this.freeTextForInfoMode?.highlightedNumber;
        this.highlightedClass = 'type-info';
        break;
      }
      case MetricModes.Comparison: {
        this.highlightedNumber = undefined;
        this.highlightedClass = undefined;

        if (this.data.previousCount > 0) {
          this.highlightedNumber = this.data.intervalPercentDiff;

          if (this.data.intervalPercentDiff === 0) {
            this.highlightedClass = 'type-info';
          } else if (this.data.intervalPercentDiff > 0) {
            this.highlightedClass = this.showReversedTrend ? 'type-error' : 'type-success';
          } else {
            this.highlightedClass = this.showReversedTrend ? 'type-success' : 'type-error';
          }
        }
        break;
      }
      default: {
        throw new Error('Invalid metric mode');
      }
    }
  }

  getSubsequentText(): string {
    if (this.metricMode === MetricModes.Info) return this.freeTextForInfoMode?.subsequentText;

    const lowerCaseIntervalType = new LowerCasePipe().transform(this.data?.intervalType);

    if (this.data?.previousCount === 0) {
      return `vs 0 previous ${lowerCaseIntervalType}`;
    }

    switch (this.numberType) {
      case NumberTypes.Count:
        return `vs ${
          this.useShortNumber ? new ShortNumberPipe().transform(this.data?.previousCount) : this.data?.previousCount
        } previous ${lowerCaseIntervalType}`;
      case NumberTypes.Currency:
        return `vs ${
          this.useShortNumber
            ? '$' + new ShortNumberPipe().transform(this.data?.previousCount)
            : new CurrencyPipe('en-AU').transform(this.data?.previousCount)
        } previous ${lowerCaseIntervalType}`;
      default:
        throw new Error('Invalid number type');
    }
  }
}
