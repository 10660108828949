import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@app/interfaces';
import { OPERATOR_ORG_ID, TENANT_ORG_ID } from '@app/modules/secure/organisations/organisations.constant';
import { ParamURLEncodingCodec } from '@app/utils/param-url-encoding-codec';
import { PaginatedReq } from '@models/api';
import { MeResponse } from '@models/me';
import { GetUserOrgMembershipsResp } from '@models/membership';
import {
  AddUserOrgMembershipReq,
  AddUserOrgMembershipResp,
  Invitation,
  InvitationStatus,
  ListInvitationResp,
  ProvisionUserReq,
  ProvisionUserResp,
  UpdateStatusReq,
  User,
  UserProfileData,
  UserRecord,
  UsersSearch,
  UsersSearchResultResp,
} from '@models/user';
import { Mutation, Query, QueryService } from '@mx51/ngx-tanstack-query-adapter';
import { ConfigService } from '@shared/services/config.service';
import { QueryKeys } from '@shared/services/query-keys.service';
import { environment as env } from 'environments/environment';
import { get } from 'lodash';
import { firstValueFrom, Observable, of } from 'rxjs';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  cachedUser: Observable<MeResponse | { organisations: any[] }>;
  userDetail: MeResponse;

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private queryKeys: QueryKeys,
    private queryService: QueryService
  ) {}

  getMemberships(): Observable<MeResponse | { organisations: any[] }> {
    if (!this.cachedUser) {
      const headers = new HttpHeaders({
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      });

      this.cachedUser = this.http.get('/api/v1/me', { headers }).pipe(
        map((res: MeResponse) => {
          this.userDetail = res;
          return res;
        }),
        catchError(() => {
          return of({ organisations: [] });
        }),
        publishReplay(1),
        refCount()
      );
    }
    return this.cachedUser;
  }

  getSelectedMembershipPersonaList(): string[] {
    const membershipGroups = get(this.userDetail, 'data.org_memberships[0].groups', []);
    return membershipGroups.map((group) => group.persona);
  }

  getSelectedMembershipPermissionList(): string[] {
    return get(this.userDetail, 'data.org_memberships[0].permissions', []);
  }

  getUserDetail() {
    return this.userDetail?.data?.user;
  }

  isOperatorUser(): boolean {
    return get(this.userDetail, 'data.org_memberships[0]', undefined)?.id?.split(':')[0] === 'o';
  }

  resetPassword(email: string): Observable<User> {
    return this.http.put<User>(`${env.api_base}users/reset-password`, { email });
  }

  resetPasswordById(userId: string): Observable<{}> {
    return this.http.post<{}>(`${env.api_base}users/${userId}/reset-password`, {});
  }

  provisionUser(user: ProvisionUserReq): Observable<ProvisionUserResp> {
    return this.http.post(`api/v1/users`, user);
  }

  getUser(userId: string): Observable<ApiResponse<UserRecord>> {
    return this.http.get<ApiResponse<UserRecord>>(`${env.api_base}users/${userId}`);
  }

  searchUsers(searchString: string, pageSize: number = 20, pageToken?: string): Observable<UsersSearchResultResp> {
    let params = new HttpParams({ encoder: new ParamURLEncodingCodec() }).set('page_size', pageSize.toString());
    params = params.set('search_string', searchString);
    if (pageToken) {
      params = params.set('page_token', pageToken);
    }
    return this.http.get<UsersSearchResultResp>(`${env.api_base}users/search-users`, { params });
  }

  searchUsers_QUERY(search: UsersSearch): Query<UsersSearchResultResp, HttpErrorResponse> {
    const { pageSize, pageToken, searchString, userTypes } = search;

    let params = new HttpParams({ encoder: new ParamURLEncodingCodec() })
      .set('page_size', (pageSize ?? 20).toString())
      .set('search_string', searchString);

    if (pageToken) {
      params = params.set('page_token', pageToken);
    }

    if (userTypes) {
      params = params.set('user_type_in', userTypes.join(','));
    }

    return this.queryService.useQuery<UsersSearchResultResp, HttpErrorResponse>({
      queryKey: this.queryKeys.secure.users.search(params),
      queryFn: () =>
        firstValueFrom(this.http.get<UsersSearchResultResp>(`${env.api_base}users/search-users`, { params })),
    });
  }

  updateUser(userId: string, userData: UserProfileData): Observable<ApiResponse<UserProfileData>> {
    return this.http.patch(`${env.api_base}users/${userId}`, userData);
  }

  updateStatus(userId: string): Mutation<ApiResponse<UserRecord>, HttpErrorResponse, UpdateStatusReq> {
    return this.queryService.useMutation<ApiResponse<UserRecord>, HttpErrorResponse, UpdateStatusReq>({
      mutationFn: (payload) =>
        firstValueFrom(
          this.http.post<ApiResponse<UserRecord>>(`${env.api_base}users/${userId}/update-status`, payload)
        ),
    });
  }

  unlockUser(userId: string): Observable<{}> {
    return this.http.post<{}>(`${env.api_base}users/${userId}/unlock`, {});
  }

  getInvitationsByUserId_QUERY(
    userId: string,
    options: PaginatedReq = {
      page_size: '999',
      status_in: [InvitationStatus.Pending, InvitationStatus.Revoked].join(','),
    }
  ): Query<ListInvitationResp, HttpErrorResponse> {
    return this.queryService.useQuery<ListInvitationResp, HttpErrorResponse>({
      queryKey: this.queryKeys.secure.user(userId).invitations.list(options),
      queryFn: () => firstValueFrom(this.getInvitations(userId, options)),
    });
  }

  resendInvitation(orgId: string, invitationId: string): Observable<ApiResponse<Invitation>> {
    const url = `${env.api_base}organisations/${orgId}/invitations/${invitationId}/resend`;

    return this.http.post<ApiResponse<Invitation>>(url, {});
  }

  revokeInvitation(orgId: string, invitationId: string): Observable<ApiResponse<Invitation>> {
    const url = `${env.api_base}organisations/${orgId}/invitations/${invitationId}/revoke`;

    return this.http.post<ApiResponse<Invitation>>(url, {});
  }

  clearData() {
    this.cachedUser = null;
    this.userDetail = null;
  }

  getUserOrgMembershipsByUserId_QUERY(
    userId: string,
    options: PaginatedReq = { page_size: '999' }
  ): Query<GetUserOrgMembershipsResp, HttpErrorResponse> {
    return this.queryService.useQuery<GetUserOrgMembershipsResp, HttpErrorResponse>({
      queryKey: this.queryKeys.secure.user(userId).orgMemberships.list(options),
      queryFn: () => firstValueFrom(this.getUserOrgMemberships(userId, options)),
    });
  }

  addUserOrgMembership(userId: string, membershipData: AddUserOrgMembershipReq): Observable<AddUserOrgMembershipResp> {
    return this.http.post<AddUserOrgMembershipResp>(`${env.api_base}users/${userId}/memberships`, membershipData);
  }

  selectedMembershipHasPersona(persona: string): boolean {
    return this.getSelectedMembershipPersonaList().includes(persona);
  }

  private getInvitations(userId: string, options: PaginatedReq): Observable<ListInvitationResp> {
    const url = `${env.api_base}users/${userId}/invitations`;

    return this.http.get<ListInvitationResp>(url, {
      params: new HttpParams({ fromObject: options }),
    });
  }

  private getUserOrgMemberships(userId: string, options: PaginatedReq): Observable<GetUserOrgMembershipsResp> {
    return (
      this.http
        .get<GetUserOrgMembershipsResp>(`${env.api_base}users/${userId}/memberships`, {
          params: new HttpParams({ fromObject: options }),
        })
        // TODO: remove the following line when the BE is able to provide acquirer_name for operator and tenant
        .pipe(map((resp) => this.patchGetUserOrgMembershipsResponse(resp)))
    );
  }

  private patchGetUserOrgMembershipsResponse(response: GetUserOrgMembershipsResp): GetUserOrgMembershipsResp {
    response.data.forEach((membership) => {
      if (membership.organisation.id === OPERATOR_ORG_ID) {
        membership.organisation.acquirer_name = 'MX51 PTY LTD';
      } else if (membership.organisation.id === TENANT_ORG_ID) {
        membership.organisation.acquirer_name = this.configService.getTenantLegalName();
      }
    });
    return response;
  }
}
