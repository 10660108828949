import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable()
export class TLFConsumerService {
  constructor(private http: HttpClient) {}

  private tlfUrl = 'api/v1/tlf'; // URL to web api

  getJob(filename: String): Observable<{ error_file_presigned_url: string }> {
    return this.http.get<{ error_file_presigned_url: string }>(`${this.tlfUrl}/${filename}`, httpOptions);
  }
}
