import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { get, isFinite } from 'lodash';
import { Observable, of, timer } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AppPreloadingStrategy implements PreloadingStrategy {
  preload(route: Route, load: Function): Observable<any> {
    const preload = get(route, 'data.preload');
    const delay = get(route, 'data.delay');

    if (preload) {
      if (delay) {
        const delayValue = isFinite(delay) ? delay : 250;
        return timer(delayValue).pipe(mergeMap(() => load()));
      } else {
        return load();
      }
    }

    return of(null);
  }
}

export const APP_ROUTES_MODULE_PROVIDER = [AppPreloadingStrategy];
