<section class="flex-fill" [attr.aria-label]="heading + ' hero metric'">
  <div class="d-flex align-items-center py-4 pr-4">
    <div role="heading" [attr.aria-level]="headingAriaLevel" class="type-size-16 type-weight-500 m-0">
      {{ heading }}
    </div>
    <msp-icon
      *ngIf="tooltipMessage"
      [config]="{ name: 'icon-info', size: 'xsmall' }"
      matTooltip="{{ tooltipMessage }}"
      [matTooltipPosition]="'above'"
      class="ml-8 d-flex align-items-center"
      tabindex="0"
      role="button"
      aria-expanded="true"
      [attr.aria-label]="tooltipMessage"
    ></msp-icon>
  </div>
  <section
    class="d-flex align-items-center hero-number-row"
    [attr.aria-label]="heading + ' value'"
    [ngClass]="{ 'opacity-10': isLoading }"
  >
    <msp-number-counter
      startNumber="0"
      [finalNumber]="data?.currentCount?.toString()"
      [numberType]="numberType"
      [useShortNumber]="numberType === numberTypes.Percentage ? false : useShortNumber"
      class="type-size-32 type-weight-700 type-font-lato"
    ></msp-number-counter>

    <div *ngIf="metricMode === metricModes.Comparison">
      <msp-icon
        *ngIf="data?.currentCount > data?.previousCount"
        [config]="showReversedTrend ? indicatorConfig.upErrorLarge : indicatorConfig.upSuccessLarge"
      ></msp-icon>
      <msp-icon
        *ngIf="data?.currentCount < data?.previousCount"
        [config]="showReversedTrend ? indicatorConfig.downSuccessLarge : indicatorConfig.downErrorLarge"
      ></msp-icon>
    </div>
  </section>
  <section
    class="d-flex align-items-center type-line-height-20 type-size-12 type-weight-400"
    [attr.aria-label]="heading + ' subtext'"
    [ngClass]="{ 'opacity-10': isLoading }"
  >
    <msp-icon
      *ngIf="metricMode === metricModes.Comparison && data?.currentCount > data?.previousCount && !!data?.previousCount"
      [config]="showReversedTrend ? indicatorConfig.upErrorSmall : indicatorConfig.upSuccessSmall"
      class="arrow"
    ></msp-icon>
    <msp-icon
      *ngIf="metricMode === metricModes.Comparison && data?.currentCount < data?.previousCount"
      [config]="showReversedTrend ? indicatorConfig.downSuccessSmall : indicatorConfig.downErrorSmall"
      class="arrow"
    ></msp-icon>
    <sd-hero-metric-subtext
      [highlightedNumber]="highlightedNumber"
      [highlightedClass]="highlightedClass"
      [subsequentText]="subsequentSubtext"
      [mode]="metricMode"
    ></sd-hero-metric-subtext>
  </section>
</section>
