import { APP_BASE_HREF } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CSP_NONCE, NgModule } from '@angular/core';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeadingsInterceptor } from '@app/interceptors/headings.interceptor';
import { HttpErrorInterceptor } from '@app/interceptors/http-error.interceptor';
import { getAngularNonce } from '@app/utils/get-angular-nonce';
import { AnalyticsModule, SentryModule, SplunkModule, TimeoutInterceptor } from '@mx51/core-tools';
import { LoginLibModule } from '@mx51/login-lib';
import { NgxTanstackQueryAdapterModule } from '@mx51/ngx-tanstack-query-adapter';
import { NgxTanstackQueryDebuggerModule } from '@mx51/ngx-tanstack-query-debugger';
import { ReleaseFlagsComponent } from '@shared/components/release-flags/release-flags.component';
import { ConfigService } from '@shared/services/config.service';
import {
  EnvironmentVariableService,
  readEnvironmentVariablesFromDocument,
} from '@shared/services/environment-variable.service';
import { SharedModule } from '@shared/shared.module';
import { METADATA } from 'environments/build-metadata';
import { environment } from 'environments/environment';
import { Settings } from 'luxon';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

const { ENV } = readEnvironmentVariablesFromDocument();

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    ReleaseFlagsComponent,
    LoginLibModule.forRoot({
      isProd: environment.production,
      apiBase: '/api/v1/auth',
      parentModule: 'auth',
      postLoginRoute: '/secure',
      consent: [
        'While you are logged onto the Support Dashboard, we can monitor your activity and use of the Support Dashboard, including to ensure it is working as intended.',
        'We will also store and analyse this data and data entered by you on the Support Dashboard. We do this to help us improve and develop the Support Dashboard and provide a more efficient experience for you.',
      ],
    }),
    SentryModule.forRoot({
      sentry: {
        dsn: 'https://c97cb18dbabc49f08fc6bd3d78c1f33b@o258962.ingest.sentry.io/1463998',
        release: METADATA.tag,
      },
      enabled: METADATA.tag !== 'empty',
    }),
    SplunkModule.forRoot({
      enabled: METADATA.tag !== 'empty' && ENV && ENV !== 'dev' && ENV !== 'local',
      sessionRecordingEnabled: false, // Currently an issue with a library used by Splunk (protobufjs) conflicts with our CSP so this cannot be enabled
      splunk: {
        realm: 'au0',
        rumAccessToken: METADATA.splunkRumToken,
        applicationName: 'support-dashboard-fe',
        deploymentEnvironment: ENV,
        version: METADATA.tag,
      },
    }),
    AnalyticsModule.forRoot({
      version: 'v2-test',
    }),
    NgxTanstackQueryAdapterModule.forRoot({}),
    NgxTanstackQueryDebuggerModule,
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    {
      provide: HTTP_INTERCEPTORS,
      useValue: new TimeoutInterceptor(),
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeadingsInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (environmentVariableService: EnvironmentVariableService) => () => environmentVariableService.init(),
      deps: [EnvironmentVariableService],
      multi: true,
    },
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigService) => {
        return () => {
          return configService.loadConfiguration();
        };
      },
      deps: [ConfigService],
      multi: true,
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 6000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
      },
    },
    {
      provide: CSP_NONCE,
      useFactory: getAngularNonce,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    Settings.defaultZone = environment.DEFAULT_TIMEZONE;
    Settings.defaultLocale = environment.DEFAULT_LOCALE;
  }
}
