import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { get } from 'lodash';

export interface FeatureConfigs {
  terminal: {
    hardwareModels: string[];
  };
}

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  tenantConfig: any;

  constructor(private http: HttpClient, @Inject(DOCUMENT) private document: Document) {}

  async loadConfiguration(): Promise<any> {
    const headers = new HttpHeaders({
      'global-error-handler': 'false',
      'Content-type': 'application/json',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    });

    await this.http
      .get('assets/config.json', { headers })
      .toPromise()
      .then((res) => {
        this.tenantConfig = res;
      })
      .catch(() => {
        // failed - set default to mx51
        this.tenantConfig = { theme: 'mx51' };
      });
  }

  /**
   * Only used in dev mode to switch configs
   */
  setChameleonUITheme() {
    (this.document.defaultView as any)?.loadTheme(this.tenantConfig.theme);
  }

  setTheme() {
    const existingStylesheet = document.head.querySelectorAll(`link[rel="stylesheet"].theme`);

    if (existingStylesheet.length === 0) {
      const timeQS = Math.random();
      const linkEl = document.createElement('link');
      linkEl.classList.add('theme');
      linkEl.rel = 'stylesheet';

      const themeFilePath = `/assets/theme/${this.tenantConfig.theme}.css?v=${timeQS}`;
      linkEl.href = themeFilePath;

      document.head.appendChild(linkEl);
    }
  }

  getFeatureConfigs(): FeatureConfigs {
    return get(this.tenantConfig, 'featureConfigs', {});
  }

  getTenantName() {
    return get(this.tenantConfig, 'tenant', '');
  }

  getTenantLegalName() {
    return get(this.tenantConfig, 'tenantLegalName', '');
  }
}
