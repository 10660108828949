import { Component, isDevMode, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { AnalyticsService } from '@mx51/core-tools';
import { LoginLibConfigService, LoginLibService, SessionNotification } from '@mx51/login-lib';
import { AuthService } from '@shared/services/auth.service';
import { ConfigService } from '@shared/services/config.service';
import { HighlightEventService } from '@shared/services/highlight-event.service';
import { InactivityDialogComponent } from 'app/modules/shared/components/inactivity-dialog/inactivity-dialog.component';
import { filter } from 'rxjs/operators';

import { EnvironmentVariableService } from './modules/shared/services/environment-variable.service';

@Component({
  selector: 'sd-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isSecure: boolean;

  constructor(
    public authService: AuthService,
    private loginLibService: LoginLibService,
    private router: Router,
    public analyticsService: AnalyticsService,
    private matDialog: MatDialog,
    private configService: ConfigService,
    private highlightEventService: HighlightEventService,
    private loginLibConfigService: LoginLibConfigService,
    private environmentVariableService: EnvironmentVariableService
  ) {
    this.setupLoginLibFeatureConfigs();
  }

  ngOnInit(): void {
    this.configService.setTheme();

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => this.handleRouteChange(this.router.url));

    if (isDevMode()) {
      this.configService.setChameleonUITheme();
    }

    this.setSessionSubscription();
  }

  setSessionSubscription(): void {
    this.loginLibService.sessionNotifications$.subscribe((notification) => {
      switch (notification) {
        case SessionNotification.Expired: {
          this.loginLibService.clearTimers();
          this.highlightEventService.clearHighlightedEvent();
          this.authService.redirectToLogin(this.router.url);
          break;
        }
        case SessionNotification.Expiring: {
          this.showInactivityDialog();
          break;
        }
      }
    });
  }

  showInactivityDialog(): void {
    this.authService.clearModals();
    this.matDialog.open(InactivityDialogComponent, {
      disableClose: true,
      autoFocus: true,
    });
  }

  handleRouteChange(url: string): void {
    this.isSecure = url.startsWith('/secure');
    this.analyticsService.logPageView(url);
  }

  private setupLoginLibFeatureConfigs(): void {
    const { singleSignOn } = this.environmentVariableService.environmentVariables;

    this.loginLibConfigService.setOptions({
      singleSignOn: {
        ...singleSignOn,
        mx51Okta: { enabled: true, buttonLabel: 'Login with mx51 Okta', urlFragment: 'mx51okta' },
      },
    });
  }
}
