import { NgModule } from '@angular/core';
import { mapToCanActivate, mapToCanActivateChild, RouterModule, Routes, TitleStrategy } from '@angular/router';
import { AuthGuard } from '@mx51/login-lib';
import { PageNotFoundComponent } from '@shared/components/page-not-found/page-not-found.component';

import { AppPreloadingStrategy } from './app-routing.module.preload';
import { CustomTitleStrategy } from './custom-title-strategy';
import { SecureGuard } from './secure.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: '/auth/login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    redirectTo: '/auth/login',
  },
  {
    path: 'reset-password',
    redirectTo: 'auth/reset-password',
  },
  {
    path: 'auth',
    loadChildren: () => import('app/modules/auth/auth.module').then((m) => m.AuthModule),
    data: { preload: true, delay: false },
  },
  {
    path: 'secure',
    loadChildren: () => import('app/modules/secure/secure.module').then((m) => m.SecureModule),
    canActivate: mapToCanActivate([AuthGuard, SecureGuard]),
    canActivateChild: mapToCanActivateChild([AuthGuard, SecureGuard]),
    data: { preload: true, delay: true },
  },
  {
    path: 'not-found',
    component: PageNotFoundComponent,
  },
  {
    path: '**',
    redirectTo: '/not-found',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: AppPreloadingStrategy, paramsInheritanceStrategy: 'always' }),
  ],
  exports: [RouterModule],
  providers: [AppPreloadingStrategy, { provide: TitleStrategy, useClass: CustomTitleStrategy }],
})
export class AppRoutingModule {}
